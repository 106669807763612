import { useState} from "react";
import { useFetchData } from "../../../utils/MagnifyHooks";
import Search from "./Search";
import { Controller } from "react-hook-form";
import { InputMask } from "primereact/inputmask";
import { states /*countries*/ } from "./FlatData";
// import { useLiveQuery } from "dexie-react-hooks";
// import { db } from "../../../utils/DataRefresher";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

export default function ClientDetails({
  formControl,
  fieldData,
  // getValues,
  watch,
  countries,
}) {
  const [clientAttibuteList, setClientAttributeList] = useState([]);
  const [managerFilter, setManagerFilter] = useState([]);
  const [partnerFilter, setPartnerFilter] = useState([]);
  const [entitiesData, setEntitiesData] = useState([]);
  const [industriesData, setIndustryData] = useState([]);

  // const authToken = useSelector((state) => state.user.bearer);
  // const apiToken = useContext(TokenProvider);
  // const apiRoot = useSelector(state => state.globals.apiRoot)

  let clientLocation = watch("clientLocation");
  let clientScope = watch("clientScope");

  const originationStateOptions = [
    { label: "Domestic", value: "domestic" },
    { label: "International", value: "international" },
  ];

  useFetchData("/operationdata/entities", entitiesData, setEntitiesData);
  useFetchData("/operationdata/industries", industriesData, setIndustryData);
  useFetchData("/operationdata/attributes", clientAttibuteList, setClientAttributeList);

  return (
    <>
      <div className="w-full border-bottom-2">
        <b>Details</b>
      </div>
      <div id="partner-selection" className="w-6 px-2">
        <h6>Client Partner</h6>
        <Controller
          name="partnerSelection"
          rules={{ required: "Must Select Client Partner" }}
          control={formControl}
          render={({ field }) => (
            <AutoComplete
              field="EMPLOYEE"
              className="w-full"
              id={field.name}
              inputId={field.name}
              placeholder="Select Partner"
              value={field.value}
              suggestions={partnerFilter}
              completeMethod={(e) => {
                console.log(fieldData.partnerList);
                return Search(e, fieldData.partnerList, setPartnerFilter, "EMPLOYEE");
              }
              }
              inputRef={field.ref}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              itemTemplate={(item) => {
                return item.EMPLOYEE;
              }}
              virtualScrollerOptions={{ itemSize: 30 }}
              dropdown
              forceSelection
            />
          )}
        />
      </div>
      <div id="manager-selection" className="w-6 px-2">
        <h6>Client Manager</h6>
        <Controller
          name="managerSelection"
          rules={{ required: "Must Select Client Manager" }}
          control={formControl}
          render={({ field }) => (
            <AutoComplete
              field="EMPLOYEE"
              className="w-full"
              id={field.name}
              inputId={field.name}
              placeholder="Select Manager"
              value={field.value}
              suggestions={managerFilter}
              completeMethod={(e) =>
                Search(e, fieldData.managerList, setManagerFilter, "EMPLOYEE")
              }
              inputRef={field.ref}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              itemTemplate={(item) => {
                return item.EMPLOYEE;
              }}
              virtualScrollerOptions={{ itemSize: 30 }}
              dropdown
              forceSelection
            />
          )}
        />
      </div>
      <div id="entity-selection" className="w-6 px-2">
        <h6>Entity Type</h6>
        <Controller
          name="entitySelection"
          rules={{ required: "Select Entity Type" }}
          control={formControl}
          render={({ field }) => (
            <Dropdown
              className="w-full"
              id={field.name}
              inputId={field.name}
              placeholder={clientScope === "IND" ? "INDIVIDUAL" : "Select Type"}
              value={field.value}
              options={entitiesData}
              optionLabel="NAME"
              onChange={(e) => field.onChange(e.value)}
              disabled={clientScope === "IND" ? true : false}
              filter
              showClear
            />
          )}
        />
      </div>
      <div id="office-selection" className="w-6 px-2">
        <h6>Industry</h6>
        <Controller
          name="industrySelection"
          rules={{ required: "Select Industry" }}
          control={formControl}
          render={({ field }) => (
            <Dropdown
              className="w-full"
              id={field.name}
              inputId={field.name}
              placeholder={clientScope === "IND" ? "Individual" : "Select Type"}
              value={field.value}
              options={industriesData}
              optionLabel="NAME"
              onChange={(e) => field.onChange(e.value)}
              disabled={clientScope === "IND" ? true : false}
              filter
              showClear
            />
          )}
        />
      </div>
      <div id="client-name" className="w-7 px-2">
        <h6>Address</h6>
        <Controller
          name="clientAddress"
          rules={{ required: "Client Address Required" }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              placeholder="Enter Address"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
      </div>
      <div id="city-selection" className="w-5 m-0 px-2">
        <h6>City</h6>
        <Controller
          name="clientCity"
          rules={{ required: "Client City Required" }}
          control={formControl}
          defaultValue={""}
          render={({ field }) => (
            <InputText
              className="w-full"
              id={field.name}
              placeholder="Enter City"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              pt={{ root: { className: "w-full" } }}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-content-start">
        <div id="origination-selection" className="w-auto px-1 align-self-end ">
          <Controller
            name="clientLocation"
            // rules={{ required: "Client city required." }}
            control={formControl}
            render={({ field }) => (
              <SelectButton
                className="justify-content-center"
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={originationStateOptions}
                // pt={{button: {className:'w-8'}}}
              />
            )}
          />
        </div>
        <div id="client-origination" className="w-3 m-0 px-3">
          <h6>{clientLocation === "international" ? "Province" : "State"}</h6>
          <Controller
            name="clientState"
            rules={{ required: "Client State Required" }}
            control={formControl}
            render={({ field }) => {
              if (clientLocation === "domestic") {
                return (
                  <Dropdown
                    className="w-full"
                    id={field.name}
                    inputId={field.name}
                    placeholder="Select State"
                    value={field.value}
                    options={states}
                    optionLabel="name"
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    filter
                    showClear
                  />
                );
              } else if (clientLocation === "international") {
                return (
                  <InputText
                    className="w-full"
                    id={field.name}
                    placeholder="Enter Province"
                    value={field.value}
                    // suggestions={cityList}
                    onChange={(e) => field.onChange(e.target.value)}
                    pt={{ root: { className: "w-full" } }}
                  />
                );
              }
            }}
          />
        </div>
        {clientLocation === "domestic" ? (
          <div id="client-zip" className="w-2 px-3">
            <h6>Zip Code</h6>
            <Controller
              name="clientPostalCode"
              rules={{ required: "Postal Code Required" }}
              control={formControl}
              render={({ field }) => {
                if (clientLocation === "domestic") {
                  return (
                    <InputMask
                      id={field.name}
                      className="w-full"
                      placeholder="Enter Zip"
                      mask="99999"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                    />
                  );
                } else if (clientLocation === "international") {
                  return (
                    <InputText
                      id={field.name}
                      className="w-full"
                      placeholder="Enter Postal Code"
                      keyfilter="int"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  );
                }
              }}
            />
          </div>
        ) : (
          <div id="client-zip" className="w-2 px-3">
            <h6>Zip Code</h6>
            <Controller
              name="clientPostalCode"
              rules={{ required: "Client postal code required." }}
              control={formControl}
              render={({ field }) => (
                <InputText
                  className="w-full"
                  id={field.name}
                  placeholder="Enter Zip"
                  value={field.value}
                  // suggestions={cityList}
                  onChange={(e) => field.onChange(e.target.value)}
                  pt={{ root: { className: "w-full" } }}
                />
              )}
            />
          </div>
        )}
        {clientLocation === "international" && (
          <div id="country-selection" className="w-3 m-0 px-3">
            <h6>Country</h6>
            <Controller
              name="clientCountry"
              rules={{ required: "Client Country Required" }}
              control={formControl}
              render={({ field }) => {
                return (
                  <Dropdown
                    className="w-full"
                    id={field.name}
                    inputId={field.name}
                    placeholder="Select Country"
                    value={field.value}
                    options={countries}
                    optionLabel="NAME"
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    filter
                    showClear
                  />
                );
              }}
            />
          </div>
        )}
      </div>
      <div
        id="client-atributes-selection"
        className="w-6 px-1 align-self-end flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">Client Attributes</h6>
        <Controller
          name="clientAttributes"
          control={formControl}
          render={({ field }) => (
            <MultiSelect
              className="w-12"
              id={field.name}
              value={field.value}
              onChange={(e) => field.onChange(e.value)}
              options={clientAttibuteList}
              name="value"
              optionLabel="NAME"
              placeholder="Select Client Attributes"
              display="chip"
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>
      <div
        id="client-gov-contractor"
        className="w-3 flex flex-wrap justify-content-center"
      >
        <h6 className="w-full text-center">
          Government Contractor or Associated With?
        </h6>
        <Controller
          name="govermentContractor"
          // rules={{
          //   required:
          //     "Must declare if client is a government contractor or associated with a government contractor.",
          // }}
          control={formControl}
          render={({ field }) => (
            <SelectButton
              className="justify-content-center"
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              // pt={{button: {className:'w-8'}}}
            />
          )}
        />
      </div>
      {clientScope === "ORG" && (
        <div
          id="client-created-after-23"
          className="w-3 flex flex-wrap justify-content-center"
        >
          <h6 className="w-full text-center">
            Client Entity Created After 12/31/2023?
          </h6>
          <Controller
            name="clientCreatedAfter2023"
            // rules={{
            //   required: "Must Declare If Client Was Formed After 12/31/2023.",
            // }}
            control={formControl}
            render={({ field }) => (
              // <Checkbox
              //   inputId={field.name}
              //   checked={field.value}
              //   onChange={(e) => field.onChange(e.checked)}
              // />
              <SelectButton
                className="justify-content-center"
                id={field.name}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                // pt={{button: {className:'w-8'}}}
              />
            )}
          />
        </div>
      )}
    </>
  );
}
