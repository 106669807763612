import { useState, useRef, useEffect, useContext } from "react";
import { useFetchData } from "../../../utils/MagnifyHooks";
import { useLocation } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../utils/DataRefresher";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../style/NCTO/ncto.css";


import ClientGeneral from "./ClientGeneral";
import ContactDetails from "./ContactDetails";
import ClientBilling from "./ClientBilling";
import Services from "./Services";
import ReviewPage from "./ReviewPage";
import {
    SubtleShadows,
    InfoMarker,
} from "./UtilityComponets";
import { PriceGuideDialog } from "./PriceGuide";
import { formFieldDefaults, trackedFields } from "./FlatData";

import { Loader } from "../../../components/general_fragments/Loaders";
import GridLoader from "react-spinners/GridLoader";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { TokenProvider } from "../../../app/App";
import Message from "../../../components/general_fragments/Message";

function ReviewFooter(
    clientObject,
    setShowReview,
    progress,
    trigger,
    setAllPanelsOpened
) {
    const saveToast = useRef(null);

    const clientName = clientObject.clientName;
    const showSuccess = () => {
        // console.log(clientObject)
        saveToast.current.show({
            severity: "success",
            summary: "Saved",
            detail: `Successfully Saved ${clientName}`,
        });
    };
    const showFailure = (error) => {
        saveToast.current.show({
            severity: "error",
            summary: "Error",
            detail: `Encountered Error: ${error}`,
        });
    };
    const saveData = async (clientObject) => {
        try {
            const addedClient = db.NEW_CLIENTS.put(clientObject);
            addedClient.then(showSuccess);
        } catch (error) {
            showFailure(error);
            console.error(error);
        }
    };

    const kickOffReviewPage = async (panelState, reviewShow) => {
        reviewShow(true);
        panelState(true);
        setTimeout(() => trigger(), 50)
    };

    return (
        <div className="flex justify-content-end">
            <Toast ref={saveToast} />
            <ProgressBar
                value={progress}
                style={{ width: "90%", height: "2.25rem", marginRight: "2.5%" }}
            />
            <Button
                className="mr-3"
                label="Save"
                onClick={() => {
                    saveData(clientObject);
                }}
                type="button"
            />
            <Button
                label="Review"
                onClick={() => {
                    kickOffReviewPage(setAllPanelsOpened, setShowReview);
                }}
                type="button"
            />
        </div>
    );
}

function getProgress(obj) {
    const numberTracked = trackedFields.length;
    let dispFields = [];
    const countFields = Object.keys(obj).map((key) => {
        if (trackedFields.includes(key)) {
            dispFields.push(obj[key]);
            return obj[key] ? 1 : 0;
        } else {
            return 0;
        }
    });
    const numFields = countFields.reduce((a, b) => a + b);
    return parseInt((numFields / numberTracked) * 100);
}

export default function NCTO(props) {
    document.title = "Magnify | New Client Take On";

    const [countries, setCountries] = useState([]);

    // const partnerList = useLiveQuery(async () => {
    //     const partners = await db.STAFF.where("CLIENT_RESPONSIBLE")
    //         .equals("true")
    //         .sortBy("EMPLOYEE");
    //     return partners;
    // });

    // const managerList = useLiveQuery(async () => {
    //     const managers = await db.STAFF.where("IS_MANAGER")
    //         .equals("true")
    //         .sortBy("EMPLOYEE");
    //     return managers;
    // });

    const staffList = useLiveQuery(async () => {
        const staffs = await db.STAFF
            .orderBy('EMPLOYEE')
            .toArray()

        const managerList = staffs.filter(staff => staff.IS_MANAGER === 'true')
        const partnerList = staffs.filter(staff => staff.CLIENT_RESPONSIBLE === 'true')

        return { allStaff: staffs, managers: managerList, partners: partnerList }
    })

    let engagementCount = 'counting'
    engagementCount = useLiveQuery(async () => {
        return await db.ENGAGEMENT.count()
    })

    const clientCodeData = useLiveQuery(async () => {
        console.log("loadingclientCodeData")
        const codes = await db.ENGAGEMENT
            .where("CLIENTSTATUS")
            .equals("ACTIVE")
            //   .and((item) => item.CLIENT_TYPE === "PARENT")
            //   .uniqueKeys(
            //   (codes) => {
            //     console.log(codes)
            //     return codes;
            //   }
            // );
            .toArray();
        // const data = await db.ENGAGEMENT.where("CLIENTCODE")
        //   .anyOf(codes.map((code) => code.CLIENTCODE))
        //   .toArray();
        // console.log(data)
        const lost = await db.ENGAGEMENT
            .where('CLIENTSTATUS')
            .notEqual('ACTIVE')
            .toArray()

        return {
            active: codes.map((code) => {
                return {
                    label: `${code.CLIENTCODE} - ${code.CLIENTNAME}`,
                    value: code.CLIENTCODE,
                    CLIENTNAME: code.CLIENTNAME
                };
            }),
            lost: lost
        };
    });

    useFetchData("/operationdata/countries", countries, setCountries);

    const user = useSelector((state) => state.user);
    const authToken = useContext(TokenProvider);
    const apiRoot = useSelector(state => state.globals.apiRoot);

    const location = useLocation();
    const navigate = useNavigate();

    const { clientData } = location.state ? location.state : {};

    const defaultValues = {
        status: "IN PROGRESS",
        // requestNumber: `${user.STAFFINDEX}-${new Date().getTime()}`,
        requestNumber: new Date().getTime(),
        requestingEntity: "BMSS",
        requestingUser: {
            STAFFINDEX: user.STAFFINDEX,
            EMPLOYEE: user.EMPLOYEE,
            STAFF_EMAIL: user.STAFF_EMAIL,
        },
        clientCountry: "United States",
        contactCountry: "United States",
        billingCountry: "United States",
        ...formFieldDefaults,
    };

    const {
        control,
        formState,
        handleSubmit,
        watch,
        getValues,
        setValue,
        trigger,
    } = useForm({ defaultValues });

    watch(trackedFields);
    watch(["contactCountry", "billingCountry", "clientCountry"]);

    const fieldData = getValues();
    const serviceData = useRef([]);
    const nctoFormRef = useRef();
    const submitToast = useRef(null);
    // eslint-disable-next-line no-unused-vars
    const [priceData, setPriceData] = useState();
    const [loading, setLoading] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const [showPriceGuide, setShowPriceGuide] = useState(false);
    const [progressVal, setProgressVal] = useState(0);
    const [panelExpandedIndex, setPanelExpandedIndex] = useState(0);
    const [allPanelsOpened, setAllPanelsOpened] = useState(false);
    //   const [originatorList, setOrginatorList] = useState(
    //     MakeExclusiveList(partnerList, managerList)
    //   );

    useEffect(() => {
        setProgressVal(getProgress(fieldData));
    }, [setProgressVal, fieldData]);

    const panelClasses =
        "ncto-panel flex flex-row flex-wrap row-gap-3 justify-content-between";

    const onFormSubmit = (data) => {
        setLoading(true);
        setShowReview(false);
        const clientName = data.clientName;
        const showSuccess = () => {
            submitToast.current.show({
                severity: "success",
                summary: "Saved",
                detail: `Successfully Submitted ${clientName}.`,
            });
        };
        const showFailure = (error) => {
            submitToast.current.show({
                severity: "error",
                summary: "Error",
                detail: `${error}`,
            });
        };

        if (data.clientRelationship === 'new') {

            // console.log(data);
            fetch(apiRoot + "/ncto/submit", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    authorization: authToken,
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`${response.statusText} Code: ${response.status} `);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    if (data.flag === "success") {
                        const key = parseInt(data.data.key.split("-")[1]);
                        db.NEW_CLIENTS.update(key, { status: data.data.status }).then(() => {
                            showSuccess();
                            setLoading(false);
                            setTimeout(() => {
                                navigate("/apps/ncto");
                            }, 2000);
                        });
                    } else {
                        showFailure(data);
                    }
                })
                .catch((error) => {
                    // setShowReview(true);
                    setLoading(false);
                    showFailure(error);
                });
        } else {

            fetch(apiRoot + "/ncto/submit", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    authorization: authToken,
                },
                body: JSON.stringify(data),
            })

            db.NEW_CLIENTS.update(data.requestNumber, { status: 'SUCCEEDED' }).then(() => {
                showSuccess();
                setLoading(false);
                setTimeout(() => {
                    navigate("/apps/ncto");
                }, 2000);
            });
        }
    };

    // useEffect(() => {
    //     setOrginatorList(MakeExclusiveList(partnerList, managerList));
    // }, [setOrginatorList, managerList, partnerList]);

    // watch();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!clientData) return;
        const buildClient = (clientData) => {
            const fields = Object.keys(clientData);
            fields.map((key) => setValue(key, clientData[key]));
        };

        buildClient(clientData);
    }, [clientData, setValue]);

    // useEffect(() => {
    //     const getPriceGuide = async () => {
    //         const token = authToken;
    //         const resp = await fetch(
    //             apiRoot + "/operationdata/price-guidelines",
    //             {
    //                 method: "GET",
    //                 mode: "cors",
    //                 headers: { authorization: token },
    //             }
    //         );
    //         return resp.json();
    //     };
    //     const prices = getPriceGuide();
    //     prices.then((data) => setPriceData(data));
    // }, [authToken]);

    const header = (
        <div className="flex justify-content-between">
            <Toast ref={submitToast} />

            {loading && (
                <div className="loader">
                    <GridLoader
                        color="#36d7b7"
                        cssOverride={{ margin: "44vh 45vw" }}
                        margin={4}
                        size={35}
                    />
                </div>
            )}

            <SubtleShadows className={"ncto-content-header"}>
                Enter New Client
            </SubtleShadows>
            <div className="flex justify-content-between w-2">
                <InfoMarker showCallBack={() => setShowPriceGuide(true)}>
                    <span className="small-info-text">Pricing Guide</span>
                </InfoMarker>
            </div>
        </div>
    );

    return (
        <form
            id="ncto"
            ref={nctoFormRef}
            onSubmit={handleSubmit(onFormSubmit)}
        >
            {!clientCodeData && (
                <div className="loader">
                    <Loader />
                </div>
            )}

            {engagementCount === 0 && (
                <Message
                    title="No available clients"
                    message={[
                        "Clients have not finished loading from the remote database, please wait to finish loading then try again.",
                        "If this error persists please contact support.",
                    ]}
                    spin={true}
                />
            )}

            <Card
                title={header}
                className="ncto-content-main"
                footer={ReviewFooter(
                    fieldData,
                    setShowReview,
                    progressVal,
                    trigger,
                    setAllPanelsOpened
                )}
            >
                {staffList && <ClientGeneral
                    fieldData={{ partnerList: staffList.partners, managerList: staffList.managers }}
                    originatorList={staffList.allStaff}
                    clientCodeData={clientCodeData}
                    panelClasses={panelClasses}
                    panelState={panelExpandedIndex === 0 || allPanelsOpened}
                    onExpand={(idx) => setPanelExpandedIndex(idx)}
                    idx={0}
                    formControl={control}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    countries={countries}
                />}
                <ContactDetails
                    panelClasses={panelClasses}
                    panelState={panelExpandedIndex === 1 || allPanelsOpened}
                    idx={1}
                    onExpand={(idx) => setPanelExpandedIndex(idx)}
                    fieldData={fieldData}
                    formControl={control}
                    getValues={getValues}
                    setValue={setValue}
                    watch={watch}
                    countries={countries}
                />
                <ClientBilling
                    panelClasses={panelClasses}
                    panelState={panelExpandedIndex === 2 || allPanelsOpened}
                    idx={2}
                    onExpand={(idx) => setPanelExpandedIndex(idx)}
                    fieldData={fieldData}
                    formControl={control}
                    getValues={getValues}
                    watch={watch}
                    setValue={setValue}
                    showPriceGuide={setShowPriceGuide}
                    countries={countries}
                />
                {staffList && <Services
                    fieldData={fieldData}
                    lists={{ partnerList: staffList.partners, managerList: staffList.managers }}
                    panelClasses={panelClasses}
                    panelState={panelExpandedIndex === 3 || allPanelsOpened}
                    onExpand={(idx) => setPanelExpandedIndex(idx)}
                    idx={3}
                    clientType={fieldData.clientScope}
                    data={serviceData}
                    control={control}
                    formSetValue={setValue}
                    getValues={getValues}
                    watch={watch}
                />}
            </Card>
            <ReviewPage
                data={fieldData}
                visible={showReview}
                onHide={() => { setShowReview(false); setAllPanelsOpened(false) }}
                submitRef={nctoFormRef}
                style={{ width: "50vw" }}
                callingPage="ncto"
                formState={formState}
                watch={watch}
                setValue={setValue}
            // allPanelsOpened={allPanelsOpened}
            />
            <PriceGuideDialog
                show={showPriceGuide}
                closeCallback={() => setShowPriceGuide(false)}
                priceData={priceData}
                apiRoot={apiRoot}
            />
            {/* <LoadTestClient setValue={setValue} data={fieldData} keys={Object.keys(testClient)} /> */}
        </form>
    );
}
