import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { SyncLoader } from "react-spinners";
import { RingGuage } from "../../../components/visualizations/charts/RingCharts";
import { Card } from "primereact/card";

import "primeicons/primeicons.css";
import "../../../style/mywip.css";

/* global Intl */
const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function CyPyMetricGauge(props) {
  const className = `${props.name}-metric-guage`;
  const p1_color = props.colors ? props.colors[0] : null;
  const p2_color = props.colors ? props.colors[1] : null;

  return (
    <div className={className}>
      <RingGuage
        min_value={props.min_value}
        max_value={props.max_value}
        colors={{ primaryColor: p1_color, secondaryColor: p2_color }}
        py={{
          data: props.py,
          color: props.colors,
        }}
        cy={{
          data: props.cy,
          color: props.colors,
        }}
        angle={props.angle}
        label={props.label}
      />
    </div>
  );
}

function UtilizationMetricCard(props) {
  const InternalCard = (cardData) => {
    const footer = () => {
      const makeComparison = (value) => {
        const calcValue = cardData.value - value;
        const dispValue = cardData.formater(calcValue.toFixed(2));
        // console.log(calcValue);
        const color =
          Math.sign(calcValue) === 1
            ? "text-green-500 pi pi-arrow-up"
            : "text-red-500 pi pi-arrow-down";
        return <span className={`${color}`}> {dispValue}</span>;
      };
      let foot = cardData.comparator ? (
        makeComparison(cardData.comparator)
      ) : (
        <span className="pi pi-ellipsis-h"></span>
      );
      return foot;
    };

    return (
      <Card
        className="col-4 m-2 text-2xl py-0 font-bold mywip-metric-card"
        title={cardData.label}
        pt={{ title: { className: "text-sm" } }}
        footer={footer}
      >
        {cardData.formater(cardData.value)}
      </Card>
    );
  };
  
  return (
    // <div className={`flex w-5 ml-3 flex-wrap justify-content-${props.location ? props.location : "center"}`}>
    <div
      className={`flex w-5 xlg:px-6 flex-wrap justify-content-evenly ${props.classTags}`}
    >
      <h3 className="text-lg w-full ml-3 text-center">{props.label}</h3>
      <InternalCard
        label="Total Hours"
        comparator={
          props.comparatorData
            ? props.comparatorData.BILLABLEHOURS +
              props.comparatorData.NONBILLABLEHOURS
            : null
        }
        value={props.data.BILLABLEHOURS + props.data.NONBILLABLEHOURS}
        formater={(value) => new Intl.NumberFormat().format(value)}
      />
      <InternalCard
        label="Utilization"
        comparator={
          props.comparatorData ? props.comparatorData.UTILIZATION : null
        }
        value={props.data.UTILIZATION}
        formater={(value) => value + "%"}
      />
      <InternalCard
        label="Billable Hours"
        value={props.data.BILLABLEHOURS}
        comparator={
          props.comparatorData ? props.comparatorData.BILLABLEHOURS : null
        }
        formater={(value) => `${new Intl.NumberFormat().format(value)} Hrs`}
      />
      <InternalCard
        label="Non-Billable Hours"
        value={props.data.NONBILLABLEHOURS}
        comparator={
          props.comparatorData ? props.comparatorData.NONBILLABLEHOURS : null
        }
        formater={(value) => `${new Intl.NumberFormat().format(value)} Hrs`}
      />
    </div>
  );
}

function RealizationMetricCard(props) {
  const InternalCard = (cardData) => {
    const footer = () => {
      const makeComparison = (value) => {
        const calcValue = cardData.value - value;
        const dispValue = cardData.formater(calcValue.toFixed(2));
        // console.log(calcValue);
        const color =
          Math.sign(calcValue) === 1
            ? "text-green-500 pi pi-arrow-up"
            : "text-red-500 pi pi-arrow-down";
        return <span className={`${color}`}> {dispValue}</span>;
      };
      let foot = cardData.comparator ? (
        makeComparison(cardData.comparator)
      ) : (
        <span className="pi pi-ellipsis-h"></span>
      );
      return foot;
    };
    // const cardDisp = cardData.formater(cardData.value);
    return (
      <Card
        className="mywip-metric-card col-4 m-2 pt-0 text-2xl font-bold"
        title={cardData.label}
        pt={{ title: { className: "text-sm" } }}
        footer={footer}
      >
        {cardData.formater(cardData.value)}
      </Card>
    );
  };
  // console.log(props)
  return (
    // <div className={`flex w-5 ml-3 flex-wrap justify-content-${props.location ? props.location : "center"}`}>
    <div
      className={`flex w-5 pl-3 pr-3 flex-wrap justify-content-around ${props.classTags}`}
    >
      <h3 className="text-lg w-full ml-3 text-center">{props.label}</h3>
      <InternalCard
        label="WIP Amount"
        value={props.data.WIPAMOUNT}
        comparator={
          props.comparatorData ? props.comparatorData.WIPAMOUNT : null
        }
        formater={(value) => USDollar.format(value)}
      />
      <InternalCard
        label="WIP Billed"
        value={props.data.WIPBILLED}
        comparator={
          props.comparatorData ? props.comparatorData.WIPBILLED : null
        }
        formater={(value) => USDollar.format(value)}
      />
      <InternalCard
        label="Effective Rate"
        value={props.data.EFF_RATE}
        comparator={props.comparatorData ? props.comparatorData.EFF_RATE : null}
        formater={(value) => USDollar.format(value)}
      />
      <InternalCard
        label="Realization"
        value={props.data.REALIZATION}
        comparator={
          props.comparatorData ? props.comparatorData.REALIZATION : null
        }
        formater={(value) => value + "%"}
      />
    </div>
  );
}

export function MyWIP() {
  document.title = `Magnify | My WIP`;

  const [pyMetrics, setPyMetrics] = useState(null);
  const [cyMetrics, setCyMetrics] = useState(null);
  const [wkMetrics, setWkMetrics] = useState(null);
  
  const userObj = useSelector((state) => state.user);
  console.log("userObj", userObj);

  useEffect(() => {

    const pyData = Object.entries(userObj).reduce((acc, [key, value]) => {
      if (key.includes("PY")) { 
        acc[key.split("PY_")[1]] = value; 
      }
      return acc;
    }, {});

    const cyData = Object.entries(userObj).reduce((acc, [key, value]) => {
      if (key.includes("CY")) { 
        acc[key.split("CY_")[1]] = value; 
      }
      return acc;
    }, {});

    const wkData = Object.entries(userObj).reduce((acc, [key, value]) => {
      if (key.includes("WK")) { 
        acc[key.split("WK_")[1]] = value; 
      }
      return acc;
    }, {});


    setPyMetrics(pyData);
    setCyMetrics(cyData);
    setWkMetrics(wkData);

  }, [userObj]);

  console.log(pyMetrics);

  const angle = 270;
  const primaryColor = "#145DA0";
  // const secondaryColor = "#2E8BC0";

  if (userObj.PY_UTILIZATION && userObj.CY_UTILIZATION && pyMetrics && cyMetrics) {
    return (
      <div className="content-panel grid justify-content-center">
        <div className="gauge-panel col-8 flex justify-content-around">
          <CyPyMetricGauge
            // py={pyMetrics.UTILIZATION}
            // cy={cyMetrics.UTILIZATION}
            py={userObj.PY_UTILIZATION}
            cy={userObj.CY_UTILIZATION}
            // colors={[primaryColor, secondaryColor]}
            label="Utilization"
            name="utilization"
            angle={angle}
          />
          <CyPyMetricGauge
            py={userObj.PY_REALIZATION}
            cy={userObj.CY_REALIZATION}
            // py={pyMetrics.REALIZATION}
            // cy={cyMetrics.REALIZATION}
            max_value="150"
            // colors={[primaryColor, secondaryColor]}
            label="Realization"
            name="realization"
            angle={angle}
          />
        </div>
        <div className="data-panel w-12 flex flex-wrap justify-content-center">

          <UtilizationMetricCard
            label="Prior Year Hours and Utilization"
            data={pyMetrics}
            location="end"
            classTags="border-right"
          />
          <UtilizationMetricCard
            label="Current Year Hours and Utilization"
            data={cyMetrics}
            location="start"
            comparatorData={pyMetrics}
          />
          <RealizationMetricCard
            label="Prior Year Effective Rate and Realization"
            data={pyMetrics}
            location="end"
            classTags="border-right"
          />
          <RealizationMetricCard
            label="Current Year Effective Rate and Realization"
            data={cyMetrics}
            location="start"
            comparatorData={pyMetrics}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="utilization-gauge flex justify-content-center">
        <h1>
          Syncing Data
          <SyncLoader
            color={primaryColor}
            cssOverride={{ textAlign: "center" }}
          />
        </h1>
      </div>
    );
  }
}
