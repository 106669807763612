import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function HrNav() {
    const user = useSelector(state => state.user)
    const { TOP_HR, TEAM_HR } = user
    return (
        <Fragment>
            <Dropdown.Header>HR Reports</Dropdown.Header>
            {TOP_HR === 1 ? <Dropdown.Item href="/reports/reviews/firm">Firm Reviews</Dropdown.Item> : <Fragment></Fragment>}
            {/* {TOP_HR === 1 ? <Dropdown.Item href="/reports/morale/firm">Firm Check Ins</Dropdown.Item> : <Fragment></Fragment>} */}
            {TOP_HR === 1 ? <Dropdown.Item href="/reports/requests/firm">Firm Requests</Dropdown.Item> : <Fragment></Fragment>}
            {/* {DEPT_HR === 1 ? <Dropdown.Item href="/reports/hr/dept">{DEPARTMENT} HR</Dropdown.Item> : <Fragment></Fragment>} */}
            
            {/* {TOP_HR === 1 && TEAM_HR === 1 ? <Dropdown.Divider></Dropdown.Divider> : <Fragment></Fragment>} */}


            {/* {TEAM_HR === 1 ? <Dropdown.Item href="/reports/reviews/team">My Team Reviews</Dropdown.Item> : <Fragment></Fragment>} */}
            {/* {TEAM_HR === 1 ? <Dropdown.Item href="/reports/morale/team">My Team Check Ins</Dropdown.Item> : <Fragment></Fragment>} */}
            {/* <Dropdown.Item href="/reports/hr/self">My HR</Dropdown.Item> */}
            {/* <Dropdown.Divider></Dropdown.Divider> */}
        </Fragment>
    )
}