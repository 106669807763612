import { Fragment } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from "react-redux";

export default function WipNav() {
    const user = useSelector(state => state.user)
    const { TOP_WIP, DEPT_WIP, RESPONSIBLE_WIP, TEAM_WIP, DEPARTMENT } = user

    return (
        <Fragment>
            <Dropdown.Header>WIP Reports</Dropdown.Header>
            {/* {TOP_WIP === 1 ? <Dropdown.Item href="/reports/wip/firm">Firm WIP</Dropdown.Item> : <Fragment></Fragment>}
            {DEPT_WIP === 1 ? <Dropdown.Item href="/reports/wip/dept">{DEPARTMENT} WIP</Dropdown.Item> : <Fragment></Fragment>}
            {TEAM_WIP === 1 ? <Dropdown.Item href="/reports/wip/team">My Homeroom WIP</Dropdown.Item> : <Fragment></Fragment>}
            {RESPONSIBLE_WIP === 1 ? <Dropdown.Item href="/reports/wip/responsible">My Client Responsible AR</Dropdown.Item> : <Fragment></Fragment>} */}
            <Dropdown.Item href="/reports/wip/self">My WIP</Dropdown.Item>
            <Dropdown.Divider />
        </Fragment>
    )
}