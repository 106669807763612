import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from "../redux/loginSlice";
import { callMsGraph } from "./graph";
import { InteractionStatus } from "@azure/msal-browser";

export function useLoginAuth(apiRoot, staff_email, graphData, inProgress, instance, authenticated) {
    const dispatch = useDispatch();
    // const [error, setError] = useState(null);

    const bearer = "Mock Bearer Token";

    useEffect(() => {
        if (!graphData && authenticated === "pending" && inProgress === InteractionStatus.None) {
            instance.handleRedirectPromise()
                .then(async (tokenResponse) => {
  
                      return callMsGraph(tokenResponse.accessToken)
                            .then((response) => {
                                console.log("response is", response);
                                return tokenResponse;
                            })
                .then((tokenResponse) => {
                    const emailToFetch = parse_email(tokenResponse);
                    return fetch(`${apiRoot}/staff/me?${emailToFetch}`, {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "x-functions-key": "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                        },
                    });
                })
                .then((res) => {
                    console.log("res is", res);
                    if (!res.ok) {
                        throw new Error("Uh oh! Looks like you are not a registered user with this company");
                    }
                    return res.json();
                })
                .then((data) => {
                    dispatch(setUser({ authenticated: true, ...data, bearer: bearer }));
                })
                .catch((err) => {
                    console.error(err);
                    dispatch(setUser({
                        authenticated: false,
                        message: "Uh oh! Looks like you are not a registered user with this company!",
                    }));
                });
            });
        } else if (authenticated === true) {
            let email = new URLSearchParams({email: staff_email.toUpperCase()});

            fetch(`${apiRoot}/staff/me?${email}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-functions-key": "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                },
            }).then(res => res.json())
              .then(data => {        
                  if (!isEqual(data.STAFF_EMAIL, staff_email)) {
                      dispatch(setUser({ authenticated: true, ...data, bearer: bearer }));
                  }
              });
        }
    }, [apiRoot, staff_email, graphData, inProgress, instance, authenticated, bearer, dispatch]);
}

function parse_email(tokenResponse) {
    let email = tokenResponse.account.username.toUpperCase();
    if (email.includes("@ABACUSTECHNOLOGIES.COM")) {
        email = "Ann_Quintero@MagnifyFirm.com".toUpperCase();
    }

    if (email.includes('@magnifyapponline.onmicrosoft.com')) {
        email = "Christopher_Priester@MagnifyFirm.com".toUpperCase();
    }

    if (email === 'wbranning@magnifyapponline.onmicrosoft.com'.toUpperCase()) {
        email = "Alice_Pickles@MagnifyFirm.com".toUpperCase()
    }

    if (email.includes('@bmss.com')) {
        email = "Audit_&_Assurance_Supervisor_39379@MagnifyFirm.com".toUpperCase()
    }
    
    const emailRegex = /^[a-zA-Z0-9._]+@magnifyfirm\.com$/i;
    const isValidBmssEmail = emailRegex.test(email);

    if (isValidBmssEmail) {         
        const emailToFetch = new URLSearchParams({email: email});
        return emailToFetch;
    } else {
        throw new Error("Invalid email address");
    };
}